import React from 'react'
import { useLocation } from 'react-router-dom'

const ErrorHandler = ({ children }) => {
  const location = useLocation()

  // refreshing this page doesnt seem to work, have them click a link instead
  switch (location.state?.errorStatusCode) {
    case 404:
      return <span className='dark:text-gray-200'>404: couldn't find whatever it was you were looking for</span>
    case 504:
      return <span className='dark:text-gray-200'>
        504: okay right, so the whole page crashed. we've told the dev team about this. someone will pay.
        <a href='/dashboard'>Back to Dashboard</a>
      </span>
    default:
      return children
  }
}

export default ErrorHandler
