import React, { Fragment, useState } from 'react'
import PropTypes from 'prop-types'

import { Dialog, Transition } from '@headlessui/react'
import classNames from '@/utils/classNamesLocal'

const useModal = () => {
  const [isOpen, setIsOpen] = useState(false)

  function openModal() { setIsOpen(true) }
  function closeModal() { setIsOpen(false) }

  return {
    openModal,
    closeModal,
    isOpen
  }
}

const Modal = (props) => {
  const { isOpen, closeModal, full } = props

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-10 overflow-y-auto mt-16"
        onClose={closeModal}
      >
        <div className="flex items-start justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          {/* <span className="inline-block h-screen align-middle" aria-hidden="true"> &#8203; </span> */}

          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div className='container mx-auto'>
              <div className={classNames(full ? 'min-w-full' : '', 'inline-block w-full max-w-md p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-gray-100 dark:bg-gray-700 dark:text-gray-200 shadow-xl rounded-2xl')}>
                { React.Children.map(props.children, (child) => {
                  return (child && <> { child } </>)
                })}
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  )
}

Modal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.object
  ]),
  full: PropTypes.bool
}

export { Modal, useModal }
