import React from 'react'
import PropTypes from 'prop-types'

import classNames from '@/utils/classNamesLocal'

const PrimaryButton = (props) => {
  const { onClick, text, disabled, className, loading } = props
  if (disabled) {
    return <button
      type="button"
      className={classNames('cursor-not-allowed inline-flex items-center justify-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white dark:bg-gray-900 dark:text-white dark:hover:bg-gray-800 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-clcpurple', className)} disabled >
      {text}
    </button>
  }

  if (loading) {
    return <button type="button" className={classNames('cursor-wait inline-flex justify-center px-4 py-2 shadow-sm text-sm font-medium rounded-md text-white bg-clcnavy hover:bg-clcnavy-alt dark:hover:bg-clcnavy-light dark:text-white  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900', className)} disabled>
      <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" fill="none" viewBox="0 0 24 24">
        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
        <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
      </svg>
      {text}
    </button>
  }

  return <button
    type="button"
    className={classNames('inline-flex justify-center px-4 py-2 shadow-sm text-sm font-medium rounded-md text-white bg-clcnavy hover:bg-clcnavy-alt dark:hover:bg-clcnavy-light dark:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900', className)} onClick={onClick}
  >
    {text}
  </button>
}

export default PrimaryButton

PrimaryButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired
}
