import React from 'react'
import PropTypes from 'prop-types'
import classNames from '@/utils/classNamesLocal'

const messages = [
  'You look nice today.',
  'Recombobulating our discombobs',
  'Brb, yeeting',
  'Cant find it. One sec',
  'Asking Buford',
  'Adding something to my Netflix watchlist',
  'We require additional servers!',
  'Yo dawg, heard you liked open projects',
  'Payload incoming',
  'Computing with computers',
  'By the power of SCIENCE!',
  'I always thought you were great',
  'You are pressing the button too hard!',
  'Sometimes I just like to sit with a tea',
  'RIP Servers',
  'Dont forget to like and subscribe',
  'You always make me smile'
]

function getMessage() {
  return messages[Math.floor(Math.random() * messages.length)]
}

const styles = {
  full: 'fixed top-0 left-0 right-0 bottom-0 w-full h-screen z-50 overflow-hidden bg-gray-700 opacity-75 text-gray-300 dark:text-white',
  inline: 'text-gray-800 dark:text-white pt-6'
}

const Loading = ({ full, noMessage, noLoadingMessage }) => {
  const showMessage = typeof (noMessage) === 'undefined'
  const showLoadingMessage = typeof (noLoadingMessage) === 'undefined'
  return <div className={classNames(full === true ? styles.full : styles.inline, 'flex flex-col items-center justify-center')}>
    <svg className="animate-spin h-5 w-5" fill="none" viewBox="0 0 24 24">
      <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
      <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
    </svg>
    { showLoadingMessage && <h2 className="text-center text-xl font-semibold">Loading...</h2> }
    { showMessage && <p className="w-1/3 text-center">{getMessage()}</p> }
  </div>
}

export default Loading

Loading.propTypes = {
  full: PropTypes.bool,
  noMessage: PropTypes.bool,
  noLoadingMessage: PropTypes.bool
}
