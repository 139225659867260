import React from 'react'
import PropTypes from 'prop-types'
import { useHistory, Link } from 'react-router-dom'
import { ChevronRightIcon, ChevronLeftIcon, HomeIcon } from '@heroicons/react/solid'

const Breadcrumbs = (props) => {
  const history = useHistory()
  const goBack = () => { history.goBack() }
  return (
    <div className='pt-6'>
      <nav className="sm:hidden" aria-label="Back">
        <a onClick={goBack} className="flex items-center text-sm font-medium text-gray-500 hover:text-gray-700 dark:text-gray-100">
          <ChevronLeftIcon className="flex-shrink-0 -ml-1 mr-1 h-5 w-5 text-gray-400" aria-hidden="true" />
          Back
        </a>
      </nav>
      <nav className="hidden sm:flex" aria-label="Breadcrumb">
        <ol className="flex items-center space-x-4">
          <li>
            <div>
              <Link to="/dashboard" className="text-sm font-medium text-gray-500 hover:text-gray-700 dark:text-gray-100">
                <HomeIcon className="flex-shrink-0 h-5 w-5" aria-hidden="true" />
                <span className="sr-only">Home</span>
              </Link>
            </div>
          </li>
          { React.Children.map(props.children, (child) => {
            return (child && <li className="flex items-center">
              <ChevronRightIcon className="flex-shrink-0 h-5 w-5 text-gray-400" aria-hidden="true" />

              { child }
            </li>)
          })}
        </ol>
      </nav>
    </div>
  )
}

export default Breadcrumbs

Breadcrumbs.propTypes = {
  disabled: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.object
  ])
}
