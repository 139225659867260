import React, { Fragment, useEffect, useState, useRef } from 'react'
import PropTypes from 'prop-types'

import { Transition } from '@headlessui/react'
import { XIcon, ExclamationIcon } from '@heroicons/react/solid'
import { useGlobalState } from '@/state'

const ProgressBar = ({ progressPercentage }) => {
  return (
    <div className='h-1 w-full bg-gray-300'>
      <div style={{ width: `${progressPercentage}%` }}
        className={`h-full ${progressPercentage < 50 ? 'bg-clcpink' : 'bg-clcpurple'}`}>
      </div>
    </div>
  )
}

export default function ErrorNotification() {
  const [errorNotification, setErrorNotification] = useGlobalState('errorNotification')
  const show = errorNotification !== null
  const [perc, setPerc] = useState(0)
  const showtimer = useRef(null)
  const perctimer = useRef(null)

  const pause = () => { clear() }

  const resume = () => {
    showtimer.current = setTimeout(() => setErrorNotification(null), perc * 48)
    setPerc(perc - 1)
  }

  const clear = () => {
    window.clearInterval(showtimer.current)
    window.clearInterval(perctimer.current)
  }

  useEffect(() => {
    if (!show) {
      clear()
      return
    }

    showtimer.current = setTimeout(() => setErrorNotification(null), 5000)
    setPerc(100)
  }, [show])

  useEffect(() => {
    perctimer.current = perc > 0 && setTimeout(() => setPerc(perc - 1), 40)
  }, [perc])

  return (
    <>
      {/* Global notification live region, render this permanently at the end of the document */}
      <div
        aria-live="assertive"
        className="fixed inset-0 z-20 flex items-end px-4 py-6 pointer-events-none sm:p-6 sm:items-start"
      >
        <div className="w-full flex flex-col items-center space-y-4 sm:items-end">
          {/* Notification panel, dynamically insert this into the live region when it needs to be displayed */}
          <Transition
            show={show}
            as={Fragment}
            enter="transform ease-out duration-300 transition"
            enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
            enterTo="translate-y-0 opacity-100 sm:translate-x-0"
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="max-w-sm w-full bg-white dark:bg-gray-800 shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden border-b-2 border-l-2 border-r-2 border-clcpurple dark:border-clcpurple-alt"
              onMouseEnter={pause}
              onMouseLeave={resume}
            >
              <ProgressBar progressPercentage={perc} />
              <div className="p-4">
                <div className="flex items-start">
                  <div className="flex-shrink-0">
                    <ExclamationIcon className="h-6 w-6 text-red-400" aria-hidden="true" />
                  </div>
                  <div className="ml-3 w-0 flex-1 pt-0.5">
                    <p className="text-sm font-medium dark:text-white text-gray-800">There was a problem. :(</p>
                    <p className="mt-1 text-sm dark:text-gray-100 text-gray-500">{errorNotification}</p>
                  </div>
                  <div className="ml-4 flex-shrink-0 flex">
                    <button
                      className="bg-white dark:bg-gray-800 rounded-md inline-flex dark:text-gray-100 text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                      onClick={() => { setErrorNotification(null) }}
                    >
                      <span className="sr-only">Close</span>
                      <XIcon className="h-5 w-5" aria-hidden="true" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Transition>
        </div>
      </div>
    </>
  )
}

ProgressBar.propTypes = {
  progressPercentage: PropTypes.number.isRequired
}
