import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'

import { useForm } from 'react-hook-form'
import { showNonSpaToast } from '@/utils/nonSpaToast'

import {PrimaryButton, DefaultButton} from '@/shared/Buttons'
import { ExclamationCircleIcon, CheckCircleIcon } from '@heroicons/react/solid'
import useQuery from '@/hooks/useQuery'

import InstructorImage from '@/pages/teachers/InstructorImage'

const InstructorForm = ({ initialInst, refreshData }) => {
  const [inst, setInst] = useState(initialInst || {})
  const [confirmDelete, setConfirmDelete] = useState(false)
  const { putpostRequest } = useQuery()
  const { formState, register, unregister, watch, handleSubmit, setValue, getValues, setError, setFocus } = useForm({
   defaultValues: {
      name: initialInst.name,
      imageUrl: initialInst.imageUrl
    }
  })

  const { errors } = formState
  const [loading, setLoading] = useState(false)

  useEffect(() => { setFocus('name') }, [setFocus])

  const onSubmit = (data) => {
    setLoading(true)
    putpostRequest(`/api/v1/teachers/instructors/${inst.id}`, 'PATCH', { instructor: { ...data } }, (err, jsonData) => {
      setLoading(false)
      if (err) { // 422 code
        if (typeof err !== 'string') { // 500 code on API
          Object.entries(err).forEach(([key, value]) => {
            setError(key, value)
          })
        }
        return
      }

      showNonSpaToast("Instructor Info Saved", true)
    })
  }

  const destroy = () => {
    setLoading(true)
    putpostRequest(`/api/v1/teachers/instructors/${inst.id}`, 'DELETE', {}, (err, jsonData) => {
      setLoading(false)
      if (err) { // 422 code
        if (typeof err !== 'string') { // 500 code on API
          Object.entries(err).forEach(([key, value]) => {
            setError(key, value)
          })
        }
        return
      }

      refreshData()
      showNonSpaToast("Instructor Deleted", true)
    })
  }

  return <li class='sm:col-span-1 bg-gray-200 dark:bg-gray-700 shadow-md transition duration-300 ease-out rounded-lg hover:shadow-xl sm:shadow-lg p-2'>
    <div className="min-w-0 flex-1 flex sm:gap-x-2 flex-col gap-2 sm:gap-y-0 sm:flex-row items-center justify-start flex-wrap sm:flex-nowrap">
      <div className="flex-shrink-0">
        <InstructorImage instructor={inst} />
      </div>
      <div className="w-full flex flex-col space-y-2">
        <div className="w-full prose-product-sm flex items-center sm:prose-product-lg">
          <input type="text" className={errors.name ? 'errors placeholder-shown:italic placeholder-gray-600 p2 py-3.5 px-4 w-full border rounded-lg' : 'placeholder-shown:italic placeholder-gray-600 p2 py-3.5 px-4 w-full border rounded-lg'} {...register('name', { required: true }) } defaultValue={inst.name} placeholder="Mr. Bigglesworth" />
        </div>
        <div className='flex gap-2 sm:gap-x-0 justify-between items-center'>
          <PrimaryButton onClick={handleSubmit(onSubmit)} loading={loading} text="Save" />
          {!confirmDelete && <div onClick={() => setConfirmDelete(true)} className='text-red-500 text-sm cursor-pointer'>Delete</div> }
          {confirmDelete && <div onClick={destroy} className='text-red-500 text-sm cursor-pointer'>Really delete?</div> }
        </div>
      </div>

    </div>
    { errors.name && <div className='mt-2 text-sm text-red-600'>This field is required.</div> }
  </li>
}

export default InstructorForm

InstructorForm.propTypes = {
  initialInst: PropTypes.object.isRequired
}
