import React from 'react'
import PropTypes from 'prop-types'

// https://gist.github.com/atomiks/520f4b0c7b537202a23a3059d4eec908
// good source on lazyloading tippy content

const ToolTip = (props) => {
  const { children } = props
  return (
    <div className="shadow-lg mt-5 md:mt-0 md:col-span-2 border-2 rounded border-clcpink dark:border-clcpurple-alt">
      <div className="sm:rounded-md sm:overflow-hidden">
        <div className="px-4 py-5 bg-white space-y-6 sm:p-6 dark:bg-gray-800 dark:text-gray-200 text-gray-800">
          { React.Children.map(children, (child) => {
            return (child && <> { child } </>)
          })}
        </div>
      </div>
    </div>
  )
}

export default ToolTip

ToolTip.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.string,
    PropTypes.object
  ])
}
