import React from 'react'
import { SunIcon, MoonIcon } from '@heroicons/react/solid'
import { useGlobalState } from '@/state'
import Cookies from 'js-cookie'

export const ThemeToggle = () => {
  const [theme, setTheme] = useGlobalState('theme')

  const rawSetTheme = (rawTheme) => {
    const isDark = document.body.classList.contains('dark')

    document.body.classList.remove(isDark ? 'dark' : 'light')
    document.body.classList.add(rawTheme)

    if (Cookies.get('clctheme') !== rawTheme) {
      fetch('/api/v1/theme/set_theme?clctheme=' + rawTheme)
    }
    window.localStorage.setItem('clc-theme', rawTheme)
  }

  const toggleTheme = () => {
    setTheme(theme === 'dark' ? 'light' : 'dark')
  }

  React.useEffect(() => {
    rawSetTheme(theme)
  }, [theme])

  return (
    <div className="dark:bg-gray-700 py-2 text-sm px-4 cursor-pointer" onClick={toggleTheme} >
      {theme === 'dark' ? (
        <SunIcon className="dark:text-yellow-500 h-5 w-5" />
      ) : (
        <MoonIcon className="text-cccpurple h-5 w-5" />
      )}
    </div>
  )
}

export default ThemeToggle
