import React from 'react'
import PropTypes from 'prop-types'

const Card = (props) => {
  const { footer, children, header, stickyHeader } = props
  return (
    <div className="mt-5 md:mt-0 md:col-span-2 border-t-2 rounded border-clcblue dark:border-clcpurple-alt">
      <div className="shadow sm:rounded-md">
        { header && <div className={`px-4 py-3 bg-gray-50 dark:bg-gray-750 text-right sm:px-6 ${stickyHeader ? 'sticky top-0' : ''}`}> {header} </div> }
        <div className="px-4 py-5 bg-white space-y-6 sm:p-6 dark:bg-gray-800">
          { React.Children.map(children, (child) => {
            return (child && <> { child } </>)
          })}
        </div>
        { footer && <div className="px-4 py-3 bg-gray-50 dark:bg-gray-750 text-right sm:px-6"> {footer} </div> }
      </div>
    </div>
  )
}

export default Card

Card.propTypes = {
  header: PropTypes.object,
  stickyHeader: PropTypes.bool,
  footer: PropTypes.object,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.string,
    PropTypes.object
  ])
}
