import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

import classNames from '@/utils/classNamesLocal'

const styles = {
  pink: {
    selectedAnchor: 'bg-clcpink-dark bg-opacity-10 border-clcpink text-clcpink hover:text-clcpink',
    unselectedAnchor: 'border-transparent text-clcpink hover:text-clcpink-dark dark:text-clcpink hover:bg-gray-200',
    defaultAnchor: 'group flex items-center border-l-4 px-3 py-2 text-sm font-medium rounded-r-md',
    selectedIcon: 'text-clcpink',
    unselectedIcon: 'text-clcpink hover:text-clcpink-dark dark:text-clcpink',
    defaultIcon: 'mr-3 flex-shrink-0 h-6 w-6'
  },
  purple: {
    selectedAnchor: 'bg-clcpurple-dark bg-opacity-10 border-clcpurple text-clcpurple hover:text-clcpurple dark:hover-text-clcpurple',
    unselectedAnchor: 'text-clcpurple border-transparent hover:text-clcpurple-dark dark:hover:text-clcpurple-dark dark:text-clcpurple hover:bg-gray-200',
    defaultAnchor: 'group flex items-center border-l-4 px-3 py-2 text-sm font-medium rounded-r-md',
    selectedIcon: 'text-clcpurple hover:text-clcpurple',
    unselectedIcon: 'text-clcpurple hover:text-clcpurple-dark dark:text-clcpurple',
    defaultIcon: 'mr-3 flex-shrink-0 h-6 w-6'
  },
  navy: {
    selectedAnchor: 'bg-clcnavy-light bg-opacity-10 border-clcnavy text-clcnavy dark:text-clcnavy hover:text-clcnavy dark:hover:text-clcnavy-light',
    unselectedAnchor: 'text-clcnavy border-transparent hover:text-clcnavy-light dark:text-clcnavy dark:hover:text-clcnavy-light hover:bg-gray-200',
    defaultAnchor: 'group flex items-center border-l-4 px-3 py-2 text-sm font-medium rounded-r-md dark:hover:text-clcnavy-light',
    selectedIcon: 'text-clcnavy hover:text-clcnavy dark:hover:text-clcnavy-light',
    unselectedIcon: 'text-clcnavy hover:text-clcnavy-light dark:hover:text-clcnavy-light',
    defaultIcon: 'mr-3 flex-shrink-0 h-6 w-6'
  }
}

function MenuLink(props) {
  const { to, children, ...rest } = props;
  const classes = 'text-clcnavy border-transparent hover:text-clcnavy-light dark:text-clcnavy-light dark:hover:text-clcnavy dark:bg-gray-700 hover:bg-gray-200 group flex items-center border-l-4 px-3 py-2 text-sm font-medium rounded-r-md'
  if (to === '/faqs' || to === '/logout') {
    return <a href={to} className={classes} {...rest}>{children}</a>
  }
  return <Link to={to} className={classes} {...rest}>{children}</Link>
}

const MainNavSidebarItem = (props) => {
  const { selected, url, name, color, icon, link, children, ...rest } = props
  const theIcon = { icon: icon } // cant render dynamic component unless it's in a hash

  if (link) {
    return (
      <Link to={url}
        className={classNames(
          selected ? styles[color].selectedAnchor : styles[color].unselectedAnchor, styles[color].defaultAnchor
        )} aria-current={selected ? 'page' : undefined} aria-label={name} {...rest}
      >
        <theIcon.icon className={classNames(
          selected ? styles[color].selectedIcon : styles[color].unselectedIcon, styles[color].defaultIcon
        )} aria-hidden="true"
        />
        {name}
      </Link>
    )
  }

  return (
    <a href={url}
      className={classNames(
        selected ? styles[color].selectedAnchor : styles[color].unselectedAnchor, styles[color].defaultAnchor
      )} aria-current={selected ? 'page' : undefined} aria-label={name} {...rest}
    >
      <theIcon.icon className={classNames(
        selected ? styles[color].selectedIcon : styles[color].unselectedIcon, styles[color].defaultIcon
      )} aria-hidden="true"
      />
      {name}
    </a>
  )
}
export default MainNavSidebarItem

MainNavSidebarItem.propTypes = {
  selected: PropTypes.bool.isRequired,
  url: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
  icon: PropTypes.func.isRequired
}
