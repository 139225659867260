import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

import { useGlobalState } from '@/state'
import useQuery from '@/hooks/useQuery'
import ImageUploadForm from '@/shared/ImageUploadForm'
import { Modal, useModal } from '@/shared/Modal'
import Loading from '@/shared/Loading'

const InstructorImage = ({ instructor }) => {
  const [url, setUrl] = useState(instructor.imageUrl)
  const [uploaded, setUploaded] = useState(false)
  const { openModal, closeModal, isOpen } = useModal()
  const { getRequest } = useQuery()

  const reloadPublicImageURL = () => {
    getRequest(`/api/v1/instructors/${instructor.id}/image`, {}, (err, jsonData) => {
      if (err) { return }
      setUrl(jsonData.url)
    })
  }

  return <>
    <div className="flex justify-center p-3 hover:opacity-75 cursor-pointer border-2 border-gray-300 border-dashed rounded-md relative">
      { !uploaded && <span>
          <img className='h-20 w-20 rounded-md' src={url} onClick={openModal} alt={instructor.name} />
        </span>
        }
      { uploaded && <div className='relative h-20 w-20 rounded-full flex items-center justify-center dark:bg-gray-700'>
        <img className='h-20 w-20 rounded-full' src={url} onClick={openModal} alt={instructor.name} />
        <div className='absolute h-20 w-20 top-8'>
          <Loading noMessage noLoadingMessage />
        </div>
      </div> }
    </div>
    <Modal isOpen={isOpen} closeModal={closeModal} >
      <h3 className="text-lg leading-6 font-medium text-gray-900 dark:text-white"> Edit Image</h3>
      <div className='flex justify-center'>
        <div className="mt-2">
          <ImageUploadForm
            objectType='Instructor'
            objectId={instructor.id}
            aspect='square'
            url={url}
            setUrl={setUrl}
            success={reloadPublicImageURL}
            didUpload={setUploaded}
          />
        </div>
      </div>
    </Modal>
  </>
}
export default InstructorImage

InstructorImage.propTypes = {
  instructor: PropTypes.object.isRequired
}
